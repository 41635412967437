.resumeContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10rem;
    margin: 0 auto;
    background-color: #1f53a4;
}

h1{
    text-decoration: underline;
}

.resume{
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
    max-width: 700px;
    height: auto;
}


@media screen and (max-width: 768px){
    .resumeContainer{
      padding-bottom: 1rem;
    }
    .resume{
        margin-top: 0.5em;
        max-width: 300px;
    }
  }

  @media screen and (max-width: 480px){
    .resumeContainer{
        padding-bottom: 1rem;
      }
      .resume{
          margin-top: 0.5em;
          max-width: 300px;
      }
    }

.container {
    position: relative;
    max-width: 800px;
    margin: 0 auto; 
  }
  
  .container .content {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0); 
    background: rgba(0, 0, 0, 0.5); 
    color: #f1f1f1; 
    width: 100%;
    padding: 40px; 
  }

  .googleLink{
   color: #40c98c;
  }