span:before{
    content: '';
    animation: animate infinite 8s;
    margin-top: 0;
}
@keyframes animate {
    0%{
        content: 'Software Engineer';
    }
    25%{
        content: 'Animal Lover';
    }
    50%{
        content: 'Foodie';
    }
    75%{
        content: 'Bourbon Enthusiast';
    }
    100%{
        content: 'Software Engineer';
    }
}

.socialico{
   font-size: 30px;
   color: black;
}
.socialico1{
    font-size: 30px;
    color: black;
 }

h1{
    margin: 0;
}

.socialico1:hover + .hide{
    display: block;
}

.hide{
    display: none;
}