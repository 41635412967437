.cards {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 2rem;
  }
  
  .card {
    margin: 40px;
    position: relative;
    max-width: 300px;
    max-height: 350px;
    box-shadow: 0 40px 60px -6px #1f53a4;
  }
  
  .card-title {
    display: block;
    text-align: center;
    color: #fff;
    background-color: #1f53a4;
    padding: 2%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .card img {
    width: 100%;
    height: 98%;
    object-fit: cover;
    display: block;
    position: relative;
  }
  
  .card-desc {
    display: block;
    font-size: 1.2rem;
    position: absolute;
    height: 0;
    top: 0;
    opacity: 0;
    padding: 18px 8%;
    background-color: white;
    overflow-y: scroll;
    transition: 0.8s ease;
  }
  
  .card:hover .card-desc {
    opacity: 1;
    height: 100%;
    width: 100%;
  }
  
  h1 {
    font-size: 2.8rem;
    color: black;
    margin: 40px 0 10px 0;
    text-align: center;
  }

  @media screen and (max-width: 768px){
    h1{
      font-size: 40px;
    }
  }

  @media screen and (max-width: 480px){
    h1{
      font-size: 32px;
    }
  }

